import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`POLIS LINKS — BEYOND MODERATION`}</h1>
    <h3>{`STANFORD UNIVERSITY, NOVEMBER 10, 2023`}</h3>
    <h4>{`Slides & Other Talks`}</h4>
    <p>{`Slides: `}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/1bKaXqI1OAcriolPg_B-aU6D3ATV3oAzDmL-_K4PqvQ4/edit?usp=sharing"
      }}>{`https://docs.google.com/presentation/d/1bKaXqI1OAcriolPg_B-aU6D3ATV3oAzDmL-_K4PqvQ4/edit?usp=sharing`}</a></p>
    <p>{`Recorded talk at Tech for Social Cohesion in SF: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/100089199377273/videos/907180710419773"
      }}>{`https://www.facebook.com/100089199377273/videos/907180710419773`}</a></p>
    <p>{`Recorded talk at Bard: `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=b4j_2JDh_Rc&t=1s&ab_channel=HannahArendtCenterforPoliticsandHumanitiesatBardCollege"
      }}>{`https://www.youtube.com/watch?v=b4j_2JDh_Rc&t=1s&ab_channel=HannahArendtCenterforPoliticsandHumanitiesatBardCollege`}</a></p>
    <h4>{`Twitter Community Notes`}</h4>
    <p>{`How to put out democracy's dumpster fire `}<a parentName="p" {...{
        "href": "https://www.theatlantic.com/magazine/archive/2021/04/the-internet-doesnt-have-to-be-awful/618079/?utm_source=twitter&utm_campaign=the-atlantic&utm_medium=social&utm_content=edit-promo&utm_term=2021-03-09T22%3A46%3A23"
      }}>{`https://www.theatlantic.com/magazine/archive/2021/04/the-internet-doesnt-have-to-be-awful/618079/?utm_source=twitter&utm_campaign=the-atlantic&utm_medium=social&utm_content=edit-promo&utm_term=2021-03-09T22%3A46%3A23`}</a></p>
    <p>{`Wired article about Birdwatch / Community Notes `}<a parentName="p" {...{
        "href": "https://www.wired.com/story/elon-musk-embraces-twitters-radical-crowdsourcing-experiment/"
      }}>{`https://www.wired.com/story/elon-musk-embraces-twitters-radical-crowdsourcing-experiment/`}</a></p>
    <p>{`Birdwatch / Community Notes paper `}<a parentName="p" {...{
        "href": "https://github.com/twitter/communitynotes/blob/main/birdwatch_paper_2022_10_27.pdf"
      }}>{`https://github.com/twitter/communitynotes/blob/main/birdwatch_paper_2022_10_27.pdf`}</a></p>
    <p>{`CompDem's Response (arxiv) `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2211.12571"
      }}>{`https://arxiv.org/abs/2211.12571`}</a></p>
    <p>{`Bridge based ranking `}<a parentName="p" {...{
        "href": "https://www.belfercenter.org/sites/default/files/files/publication/TAPP-Aviv_BridgingBasedRanking_FINAL_220518_0.pdf"
      }}>{`https://www.belfercenter.org/sites/default/files/files/publication/TAPP-Aviv_BridgingBasedRanking_FINAL_220518_0.pdf`}</a></p>
    <h5>{`Usage of Polis`}</h5>
    <p>{`Taiwan: MIT Technology Review "The simple but ingenious system Taiwan uses to crowdsource its laws": `}<a parentName="p" {...{
        "href": "https://www.technologyreview.com/s/611816/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/"
      }}>{`https://www.technologyreview.com/s/611816/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/`}</a></p>
    <p>{`UK: PolicyLab case study: `}<a parentName="p" {...{
        "href": "https://twitter.com/PolicyLabUK/status/1579747586092986368"
      }}>{`https://twitter.com/PolicyLabUK/status/1579747586092986368`}</a></p>
    <p>{`Finland: Sitra `}</p>
    <h4>{`Scoop / Biodiversity`}</h4>
    <p>{`Automatic polis report `}<a parentName="p" {...{
        "href": "https://pol.is/report/r4tykwac8thvzv35jrn53"
      }}>{`https://pol.is/report/r4tykwac8thvzv35jrn53`}</a></p>
    <p>{`Manual faciliator report `}<a parentName="p" {...{
        "href": "https://img.scoop.co.nz/media/pdfs/1911/Biodiversity_HiveMind_Final_Report_Scoop.pdf"
      }}>{`https://img.scoop.co.nz/media/pdfs/1911/Biodiversity_HiveMind_Final_Report_Scoop.pdf`}</a></p>
    <p>{`Reflection from Department of Conservation `}<a parentName="p" {...{
        "href": "https://pep.org.nz/2020/12/01/doc-tries-to-restore-e-democracy/"
      }}>{`https://pep.org.nz/2020/12/01/doc-tries-to-restore-e-democracy/`}</a></p>
    <h4>{`Old & New Models of Public Opinion`}</h4>
    <p>{`Jill Lepore If/Then `}<a parentName="p" {...{
        "href": "https://www.amazon.com/If-Then-Simulmatics-Corporation-Invented/dp/1631496107"
      }}>{`https://www.amazon.com/If-Then-Simulmatics-Corporation-Invented/dp/1631496107`}</a></p>
    <h4>{`Simulation`}</h4>
    <p>{`Thread on simulation: `}<a parentName="p" {...{
        "href": "https://twitter.com/colinmegill/status/1651368105992671232"
      }}>{`https://twitter.com/colinmegill/status/1651368105992671232`}</a></p>
    <h4>{`Open Research & Development in LLMs`}</h4>
    <p>{`Summarization `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/issues/915"
      }}>{`https://github.com/compdemocracy/polis/issues/915`}</a></p>
    <p>{`"Opportunities and Risks of LLMs for Scalable Deliberation with Polis"
`}<a parentName="p" {...{
        "href": "https://twitter.com/AnthropicAI/status/1671950647393124352"
      }}>{`https://twitter.com/AnthropicAI/status/1671950647393124352`}</a></p>
    <p>{`Coy NYT piece "Can A.I. and Democracy Fix Each Other?" `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/2023/04/05/opinion/artificial-intelligence-democracy-chatgpt.html"
      }}>{`https://www.nytimes.com/2023/04/05/opinion/artificial-intelligence-democracy-chatgpt.html`}</a></p>
    <h4>{`OpenAI`}</h4>
    <p>{`OpenAI call for grants `}<a parentName="p" {...{
        "href": "https://openai.com/blog/democratic-inputs-to-ai"
      }}>{`https://openai.com/blog/democratic-inputs-to-ai`}</a></p>
    <p>{`Tweet about the call `}<a parentName="p" {...{
        "href": "https://twitter.com/OpenAI/status/1661811329957781504"
      }}>{`https://twitter.com/OpenAI/status/1661811329957781504`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      